<script>
import Layout from "@/router/layouts/auth";
import { api } from "@/state/services";
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import Languages from "@/components/widgets/languages";

export default {
  locales: {
    pt: {
      'Recover': 'Recuperar',
      'Remember It?': 'Lembrou-se?',
      'Sign In here': 'Entrar',
      'A code for creating a new password has been sent to your email and you have up to 24 hours to change your password.': 'Um código para criar uma nova senha foi enviado ao seu e-mail e você tem até 24 horas para alterar sua senha.',
    },
    es: {
      'Recover': 'Recuperar',
      'Remember It?': '¿Recuerdalo?',
      'Sign In here': 'Identifícate aquí',
      'A code for creating a new password has been sent to your email and you have up to 24 hours to change your password.': 'Se ha enviado un código para crear una nueva contraseña a su correo electrónico y tiene hasta 24 horas para cambiar su contraseña.',
    }
  },
  components: {
    Layout,
    Languages
  },
  data() {
    return {
      loading: false,
      success: false,
      cnpj: "",
    };
  },
  computed: {
    ...mapState({
      alert: state => state.alert
    })
  },
  validations: {
    cnpj: { required },
  },
  methods: {
    recoverSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.loading = true

        if (this.cnpj) {
          api
            .post('password/recover', {
              cnpj: this.cnpj,
            })
            .then(response => {
              if (response.data.status == 'success') {
                this.cnpj = ''
                this.$v.$reset()

                this.alert.type = 'alert-success'
                this.alert.message = response.data.message

                this.success = true
              } else {
                this.alert.type = 'alert-danger'
                this.alert.message = response.data.message
              }

              this.loading = false
            })
        }
      }
    }
  }
};
</script>

<template>
  <Layout>
    <div id="auth" class="d-flex">
      <div class="login-content">
        <div class="pt-5 text-center">
          <router-link class="align-middle" tag="a" to="/">
            <img class="w-100" style="max-width: 150px" src="@/assets/images/logo.png" />
          </router-link>
        </div>
        <div class="py-5">
          <div class="border rounded py-3 mb-4">
            <h5 class="m-0 font-size-14 font-weight-normal text-uppercase text-center">
              Área dos <strong>Franqueados</strong>
            </h5>
          </div>
          <div v-if="success">
            {{ t(this.alert.message) }}
          </div>
          <b-form v-else @submit.prevent="recoverSubmit">
            <div v-if="alert.message" :class="'alert ' + alert.type">
              {{ t(alert.message) }}
            </div>
            <b-form-group id="cnpj" label="CNPJ" label-for="cnpj">
              <b-form-input id="cnpj" v-model="cnpj" type="text" :class="{ 'is-invalid': $v.cnpj.$error }" v-mask="'##.###.###/####-##'"></b-form-input>
              <div v-if="$v.cnpj.$error" class="invalid-feedback">
                <span v-if="!$v.cnpj.required">O CNPJ é obrigatório</span>
              </div>
            </b-form-group>
            <div class="mt-4">
              <b-button :disabled="loading == true" type="submit" variant="default" class="btn-lg btn-block">
                {{ t("Recover") }}
                <b-spinner v-if="loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
              </b-button>
            </div>
          </b-form>
          <div class="my-4">
            <p class="text-dark">
              {{ t("Remember It?") }}
              <router-link tag="a" to="/login" class="font-weight-medium text-muted">{{ t("Sign In here")
                }}</router-link>
            </p>
            <Languages class="d-none" type="dropup"></Languages>
            <p class="d-none mt-5 font-size-12 text-uppercase text-muted text-center">
              <a class="text-muted font-size-11" target="_blank" href="https://m2n.com.br/?utm_source=multiversobrasil.com&utm_medium=link&utm_campaign=logo">
                TECNOLOGIA POR<br />
                <img alt="M2N" style="width: 50px" src="@/assets/images/logo-m2n.svg" />
              </a>
            </p>
          </div>
        </div>
      </div>
      <div class="flex-fill login-image d-none d-lg-block"></div>
    </div>
  </Layout>
</template>
